import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import bg from "assets/images/privacy-bg.png";

const Containter = styled(Stack)`
  background: #fff;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;

const Banner = styled(Stack)`
  width: 100%;
  height: 400px;
  background: linear-gradient(to right bottom, #000000, #320900);
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      height: "300px",
    },
  })};
`;

const PrCon = styled(Stack)`
  height: 100%;
  width: 960px;
  padding: 80px 0 160px 0;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 50px 20px",
    },
  })};
`;

const Head = styled(Typography)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
  })};
`;

const BannerInner = styled(Stack)`
  width: 100%;
  height: 500px;
  background-image: url(${bg});
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
`;

const Title = styled(Typography)`
  color: #000;
  font-family: Arboria-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const InfoP = styled(Typography)`
  color: #000;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const Terms = () => {
  return (
    <Containter>
      <Banner>
        <BannerInner
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Head pt={70} variant="h1">
            Terms of Service
          </Head>
        </BannerInner>
      </Banner>
      <Stack justifyContent="center" alignItems="center" direction="row">
        <PrCon>
          <InfoP>
            By accessing this website and any materials presented herein (the
            “Site”), you agree to the following terms and conditions pertaining
            to the use of the Site. Bracle Data Association and its affiliates
            (collectively, “Bracle”) reserve the right to change the terms,
            conditions and notices under which this Site is offered without
            notice at any time. Each use of the Site constitutes your agreement
            to be bound by the then-current terms and conditions set forth in
            these Terms of Use.
          </InfoP>
          <InfoP>
            The Terms of Use incorporate the Data Protection and Privacy Policy.
          </InfoP>
          <Title>LIMITED LICENSE</Title>
          <InfoP>
            Nothing contained in these Terms of Use or the Site itself grants or
            will be construed to grant to you or any third party any title or
            interest in or any license or right to use or reproduce, any image,
            text, software, code, trademark, logo or service mark contained in
            the Site, including without limitation the names or logos of Bracle.
            Bracle reserves, and will enforce to the fullest extent possible,
            any and all rights that it may have with respect to copyright and
            trademark ownership of all materials contained in the Site. Bracle
            grants you a limited, nonexclusive license to display and otherwise
            use portions of the Site solely for your own private, non-commercial
            informational purposes only, and to print pages from the Site only
            in connection with that use. You may not modify, distribute,
            transmit, perform, reproduce, publish, license, create derivative
            works from, transfer or sell any text, graphics, logos and other
            source-identifying symbols, designs, icons, images, or other
            information, software or code obtained from the Site.
          </InfoP>
          <Title>NO WARRANTY</Title>
          <InfoP>
            Bracle and its respective officers, managers, directors, principals,
            agents, and employees make no express or implied representations or
            warranties regarding the accuracy, reliability, completeness,
            suitability, or other characteristics of the information and
            materials contained on or presented through the Site. Any content on
            the Site is subject to change without prior notice. All such
            information and materials are provided "as is" without any warranty
            of any kind. Bracle further disclaims all warranties and conditions
            regarding such information and materials, including all implied
            warranties and conditions of merchantability, fitness for a
            particular purpose, title, non-infringement, and availability.
          </InfoP>
          <Title>LIMITATION OF LIABILITY</Title>
          <InfoP>
            In no event shall Bracle, and its respective officers, managers,
            directors, principals, agents, and employees be liable for any
            claims, liabilities, losses, costs, or damages, including direct,
            indirect, punitive, incidental, special, or consequential damages,
            arising out of or in any way connected with (i) the use of or
            inability to use the Site or any delay in using the Site, (ii) any
            information and materials obtained through the Site, or (iii)
            otherwise arising out of the use of the Site; regardless of the
            theory of liability (whether arising in contract, tort, strict
            liability, or otherwise). Such limitations shall apply even if
            Bracle or any of its respective officers, directors, principals,
            agents, or employees have been advised of the possibility of
            damages.
          </InfoP>
          <Title>GENERAL</Title>
          <InfoP>
            Should any provision of these Terms of Use be deemed invalid or
            unenforceable in any jurisdiction, such provision shall be modified
            to the minimum extent necessary so as not to be deemed invalid or
            unenforceable, and these Terms of Use shall be construed to achieve
            the expressed intent to the maximum extent possible within that
            jurisdiction. Any such modification, invalidity, or unenforceability
            shall be strictly confined to that provision and jurisdiction.
          </InfoP>
          <Title>INTELLECTUAL PROPERTY</Title>
          <InfoP>
            Bracle holds intellectual property rights associated with the Bracle
            Network and certain other products (referred to as the "Products").
            Bracle safeguards and upholds such intellectual property rights
            solely for defensive purposes, such as ensuring the integrity of the
            Products and providing clarity and certainty to end users. When
            appropriate, Bracle will make the Products accessible to the
            community under open-source licenses and permissive brand use
            guidelines. By protecting IP rights in this manner and making them
            available to the community, Bracle facilitates contributions to the
            ecosystem surrounding each of the Products without necessitating a
            central role in ongoing development, operation, and maintenance.
          </InfoP>
        </PrCon>
      </Stack>
    </Containter>
  );
};

export default Terms;
