import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const PriceFeeds: React.FC<IconProps> = ({
  width = "20px",
  height = "20px",
}) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
      >
        <g clip-path="url(#clip0_298_1018)">
          <path
            d="M3.5405 3.53883C3.39455 3.67316 3.20231 3.74588 3.00399 3.74177C2.80566 3.73766 2.6166 3.65704 2.47634 3.51678C2.33607 3.37651 2.25546 3.18745 2.25135 2.98913C2.24724 2.7908 2.31995 2.59857 2.45429 2.45261C5.45617 -0.549268 10.3238 -0.549268 13.3256 2.45261C16.3275 5.45449 16.3275 10.3221 13.3256 13.324C10.3238 16.3258 5.45617 16.3258 2.45429 13.324C1.43006 12.3006 0.715661 11.0088 0.393343 9.59725C0.0710248 8.18571 0.153892 6.71184 0.632405 5.34534C0.663435 5.24723 0.713847 5.15634 0.780652 5.07808C0.847458 4.99981 0.929296 4.93576 1.02132 4.8897C1.11334 4.84365 1.21367 4.81655 1.31636 4.80999C1.41905 4.80343 1.52201 4.81756 1.61914 4.85153C1.71627 4.8855 1.80559 4.93863 1.88181 5.00776C1.95803 5.07689 2.01959 5.16063 2.06285 5.25399C2.10611 5.34736 2.13018 5.44846 2.13364 5.5513C2.1371 5.65414 2.11988 5.75663 2.08299 5.8527C1.58206 7.27923 1.62418 8.8403 2.20131 10.2377C2.77844 11.6352 3.85012 12.7711 5.21167 13.4284C6.57322 14.0858 8.1292 14.2185 9.58243 13.8013C11.0357 13.3841 12.2843 12.4462 13.0898 11.1667C13.8953 9.88725 14.2013 8.35588 13.9492 6.86511C13.6972 5.37434 12.9049 4.02865 11.7235 3.08507C10.5422 2.14149 9.05462 1.66616 7.54501 1.74986C6.0354 1.83356 4.60953 2.47043 3.53973 3.53883H3.5405Z"
            fill="white"
            className="fill-color"
          />
          <path
            d="M10.1415 5.95625C10.2424 5.95625 10.3424 5.93636 10.4357 5.89773C10.5289 5.8591 10.6137 5.80248 10.6851 5.73109C10.7564 5.65971 10.8131 5.57497 10.8517 5.4817C10.8903 5.38843 10.9102 5.28847 10.9102 5.18752C10.9102 5.08657 10.8903 4.98661 10.8517 4.89334C10.8131 4.80007 10.7564 4.71533 10.6851 4.64395C10.6137 4.57256 10.5289 4.51594 10.4357 4.47731C10.3424 4.43868 10.2424 4.41879 10.1415 4.41879H6.36704C5.95928 4.41879 5.56822 4.58077 5.27989 4.8691C4.99157 5.15743 4.82958 5.54849 4.82958 5.95625V7.11549C4.82958 7.52325 4.99157 7.91431 5.27989 8.20263C5.56822 8.49096 5.95928 8.65295 6.36704 8.65295H9.42658V10.1843H5.58294C5.37906 10.1843 5.18353 10.2652 5.03936 10.4094C4.8952 10.5536 4.81421 10.7491 4.81421 10.953C4.81421 11.1569 4.8952 11.3524 5.03936 11.4966C5.18353 11.6407 5.37906 11.7217 5.58294 11.7217H9.42658C9.62848 11.7217 9.8284 11.6819 10.0149 11.6047C10.2015 11.5274 10.371 11.4142 10.5137 11.2714C10.6565 11.1286 10.7697 10.9591 10.847 10.7726C10.9243 10.5861 10.964 10.3862 10.964 10.1843V8.65295C10.964 8.24519 10.802 7.85413 10.5137 7.5658C10.2254 7.27747 9.83433 7.11549 9.42658 7.11549H6.36704V5.95625H10.1415Z"
            fill="white"
            className="fill-color"
          />
          <path
            d="M8.65782 12.4966C8.65782 12.7005 8.57683 12.896 8.43266 13.0402C8.2885 13.1843 8.09297 13.2653 7.88909 13.2653C7.68521 13.2653 7.48968 13.1843 7.34552 13.0402C7.20135 12.896 7.12036 12.7005 7.12036 12.4966V10.9591C7.12036 10.7552 7.20135 10.5597 7.34552 10.4156C7.48968 10.2714 7.68521 10.1904 7.88909 10.1904C8.09297 10.1904 8.2885 10.2714 8.43266 10.4156C8.57683 10.5597 8.65782 10.7552 8.65782 10.9591V12.4966ZM8.65782 4.80623C8.65782 5.01011 8.57683 5.20564 8.43266 5.3498C8.2885 5.49397 8.09297 5.57496 7.88909 5.57496C7.68521 5.57496 7.48968 5.49397 7.34552 5.3498C7.20135 5.20564 7.12036 5.01011 7.12036 4.80623V3.27492C7.12036 3.07104 7.20135 2.87551 7.34552 2.73135C7.48968 2.58719 7.68521 2.5062 7.88909 2.5062C8.09297 2.5062 8.2885 2.58719 8.43266 2.73135C8.57683 2.87551 8.65782 3.07104 8.65782 3.27492V4.80623ZM13.6699 12.2629L16.3835 14.9765C17.1084 15.7006 16.0215 16.7884 15.2965 16.0635L12.5829 13.3499C11.8588 12.6257 12.9458 11.5387 13.6707 12.2629H13.6699Z"
            fill="white"
            className="fill-color"
          />
        </g>
        <defs>
          <clipPath id="clip0_298_1018">
            <rect
              width="16.9"
              height="16.1433"
              fill="white"
              transform="translate(0.199951 0.200012)"
              className="fill-color"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PriceFeeds;
