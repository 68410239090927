import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Logo: React.FC<IconProps> = ({ width = "124px", height = "34px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="34"
        viewBox="0 0 124 34"
        fill="none"
      >
        <g clip-path="url(#clip0_298_112)">
          <path
            d="M9.31226 0V9.63188H4.65357V24.6997H9.31226V33.81H4.66124V29.3508H0V4.9783H4.66124V0H9.31226Z"
            fill="#FF6D00"
          />
          <path
            d="M24.1602 17.1773C25.362 18.4737 26.0958 20.2072 26.0958 22.1147C26.0958 24.4057 25.0347 26.4487 23.3778 27.7783C22.2809 28.6604 20.9232 29.228 19.4402 29.3508H18.8342V33.856H14.1806V24.6972H18.3611C18.4813 24.7151 18.6015 24.7227 18.7242 24.7227C20.1663 24.7227 21.3348 23.5542 21.3348 22.1121C21.3348 20.6701 20.1663 19.5015 18.7242 19.5015H9.30969V14.848H18.7242C20.1663 14.848 21.3348 13.6795 21.3348 12.2374C21.3348 10.7953 20.1663 9.62676 18.7242 9.62676H14.1806V0.048584H18.8342V4.9783C20.555 4.9783 22.1351 5.57662 23.3778 6.57637C25.0347 7.90596 26.0958 9.94893 26.0958 12.2399C26.0958 14.1474 25.362 15.881 24.1602 17.1773Z"
            fill="#FF6D00"
          />
          <path
            d="M49.6424 10.7109C52.6544 10.7109 53.7872 11.8896 53.7872 14.311V15.3593C53.7872 16.602 53.3065 17.5199 52.3911 18.131C53.4164 18.7191 53.8971 19.6575 53.8971 20.9462V22.2119C53.8971 24.5233 52.9383 25.9859 50.0362 25.9859H37.0778C36.6405 25.9859 36.4232 25.7685 36.4232 25.3313V11.368C36.4232 10.9308 36.6405 10.7134 37.0778 10.7134H49.645L49.6424 10.7109ZM39.76 17.0597H48.704C50.1436 17.0597 50.5808 16.6225 50.5808 15.7736V15.0321C50.5808 14.1371 50.1001 13.7229 48.9674 13.7229H40.0438C39.8469 13.7229 39.76 13.8099 39.76 14.0067V17.0622V17.0597ZM39.76 19.4376V22.6874C39.76 22.8843 39.8469 22.9713 40.0438 22.9713H48.9878C50.1436 22.9713 50.6012 22.557 50.6012 21.6391V20.8107C50.6012 19.9158 50.1436 19.4351 48.704 19.4351H39.76V19.4376Z"
            fill="white"
          />
          <path
            d="M67.643 14.0067C67.9038 14.0067 68.0368 14.1371 68.0368 14.4005V16.2338C68.0368 16.5176 67.9064 16.6276 67.643 16.6276H61.8388C59.9621 16.6276 59.4609 17.1952 59.4609 19.0285V25.5077C59.4609 25.835 59.3075 25.9884 58.9802 25.9884H56.8426C56.5154 25.9884 56.3415 25.835 56.3415 25.5077V18.4379C56.3415 15.275 57.6072 14.0093 61.5346 14.0093H67.643V14.0067Z"
            fill="white"
          />
          <path
            d="M79.4687 14.0067C83.3961 14.0067 84.6618 15.2724 84.6618 18.4353V24.2395C84.6618 25.6586 84.3115 25.9859 83.0484 25.9859H72.6622C70.0874 25.9859 69.3025 25.0475 69.3025 22.8869V21.8385C69.3025 20.0052 70.0874 18.9799 72.6622 18.9799H81.5424V18.5657C81.5424 17.1696 81.0617 16.625 79.1644 16.625H70.044C69.7832 16.625 69.6502 16.5151 69.6502 16.2312V14.3979C69.6502 14.1371 69.7806 14.0042 70.044 14.0042H79.4687V14.0067ZM81.5424 22.8434V20.9232H73.5571C72.6622 20.9232 72.3349 21.2939 72.3349 21.992V22.4496C72.3349 23.2346 72.6622 23.5849 73.5571 23.5849H80.9108C81.3685 23.5849 81.5424 23.388 81.5424 22.8434Z"
            fill="white"
          />
          <path
            d="M100.435 14.0067C100.696 14.0067 100.829 14.1371 100.829 14.4005V16.2338C100.829 16.5176 100.699 16.6276 100.435 16.6276H91.9695C90.0722 16.6276 89.5915 17.1952 89.5915 19.0285V20.9692C89.5915 22.8025 90.0722 23.3701 91.9695 23.3701H100.435C100.696 23.3701 100.829 23.5005 100.829 23.7639V25.5972C100.829 25.858 100.699 25.991 100.435 25.991H91.6652C87.7378 25.991 86.4721 24.7483 86.4721 21.5829V18.4404C86.4721 15.2775 87.7378 14.0119 91.6652 14.0119H100.435V14.0067Z"
            fill="white"
          />
          <path
            d="M105.539 9.62164C105.866 9.62164 106.02 9.77506 106.02 10.1023V25.5052C106.02 25.8324 105.866 25.9859 105.539 25.9859H103.401C103.074 25.9859 102.921 25.8324 102.921 25.5052V10.1023C102.921 9.77506 103.074 9.62164 103.401 9.62164H105.539Z"
            fill="white"
          />
          <path
            d="M119.896 14.0067C122.885 14.0067 123.67 14.9451 123.67 17.5839V19.6575C123.67 20.5959 123.276 20.9002 122.427 20.9002H111.41V21.4243C111.41 22.7999 111.911 23.365 113.811 23.365H122.908C123.169 23.365 123.302 23.4954 123.302 23.7588V25.5921C123.302 25.8529 123.171 25.9859 122.908 25.9859H113.504C109.576 25.9859 108.311 24.7432 108.311 21.5777V18.4353C108.311 15.2724 109.576 14.0067 113.504 14.0067H119.896ZM111.41 18.9595H120.246C120.507 18.9595 120.617 18.8725 120.617 18.5887V17.6733C120.617 16.7784 120.29 16.4307 119.285 16.4307H113.808C111.911 16.4307 111.407 16.9753 111.407 18.3714V18.9595H111.41Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_298_112">
            <rect width="123.67" height="33.8586" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
