import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const LogoDark: React.FC<IconProps> = ({
  width = "124px",
  height = "34px",
}) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="34"
        viewBox="0 0 124 34"
        fill="none"
      >
        <path
          d="M9.31226 0V9.63188H4.65357V24.6997H9.31226V33.81H4.66124V29.3508H0V4.9783H4.66124V0H9.31226Z"
          fill="#FF6D00"
        />
        <path
          d="M24.1601 17.1773C25.3618 18.4737 26.0957 20.2072 26.0957 22.1147C26.0957 24.4057 25.0346 26.4487 23.3777 27.7783C22.2808 28.6604 20.923 29.228 19.44 29.3508H18.8341V33.856H14.1805V24.6972H18.361C18.4812 24.7151 18.6014 24.7227 18.7241 24.7227C20.1662 24.7227 21.3347 23.5542 21.3347 22.1121C21.3347 20.6701 20.1662 19.5015 18.7241 19.5015H9.30957V14.848H18.7241C20.1662 14.848 21.3347 13.6795 21.3347 12.2374C21.3347 10.7953 20.1662 9.62676 18.7241 9.62676H14.1805V0.048584H18.8341V4.9783C20.5549 4.9783 22.135 5.57662 23.3777 6.57637C25.0346 7.90596 26.0957 9.94893 26.0957 12.2399C26.0957 14.1474 25.3618 15.881 24.1601 17.1773Z"
          fill="#FF6D00"
        />
        <path
          d="M49.6421 10.7109C52.6541 10.7109 53.7868 11.8896 53.7868 14.311V15.3593C53.7868 16.602 53.3061 17.5199 52.3907 18.131C53.4161 18.7191 53.8968 19.6575 53.8968 20.9462V22.2119C53.8968 24.5233 52.9379 25.9858 50.0358 25.9858H37.0774C36.6402 25.9858 36.4229 25.7685 36.4229 25.3313V11.368C36.4229 10.9308 36.6402 10.7134 37.0774 10.7134H49.6446L49.6421 10.7109ZM39.7596 17.0597H48.7037C50.1432 17.0597 50.5805 16.6224 50.5805 15.7736V15.0321C50.5805 14.1371 50.0998 13.7229 48.967 13.7229H40.0434C39.8466 13.7229 39.7596 13.8098 39.7596 14.0067V17.0622V17.0597ZM39.7596 19.4376V22.6874C39.7596 22.8843 39.8466 22.9713 40.0434 22.9713H48.9875C50.1432 22.9713 50.6009 22.557 50.6009 21.6391V20.8107C50.6009 19.9157 50.1432 19.435 48.7037 19.435H39.7596V19.4376Z"
          fill="black"
        />
        <path
          d="M67.6424 14.0067C67.9032 14.0067 68.0361 14.1371 68.0361 14.4005V16.2338C68.0361 16.5176 67.9057 16.6276 67.6424 16.6276H61.8382C59.9614 16.6276 59.4602 17.1952 59.4602 19.0285V25.5077C59.4602 25.835 59.3068 25.9884 58.9795 25.9884H56.842C56.5147 25.9884 56.3408 25.835 56.3408 25.5077V18.4379C56.3408 15.275 57.6065 14.0093 61.5339 14.0093H67.6424V14.0067Z"
          fill="black"
        />
        <path
          d="M79.468 14.0067C83.3954 14.0067 84.6611 15.2724 84.6611 18.4353V24.2395C84.6611 25.6586 84.3108 25.9859 83.0477 25.9859H72.6615C70.0867 25.9859 69.3018 25.0475 69.3018 22.8869V21.8385C69.3018 20.0052 70.0867 18.9799 72.6615 18.9799H81.5417V18.5657C81.5417 17.1696 81.061 16.625 79.1637 16.625H70.0433C69.7825 16.625 69.6495 16.5151 69.6495 16.2312V14.3979C69.6495 14.1371 69.7799 14.0042 70.0433 14.0042H79.468V14.0067ZM81.5417 22.8434V20.9232H73.5564C72.6615 20.9232 72.3342 21.2939 72.3342 21.992V22.4496C72.3342 23.2346 72.6615 23.5849 73.5564 23.5849H80.9101C81.3678 23.5849 81.5417 23.388 81.5417 22.8434Z"
          fill="black"
        />
        <path
          d="M100.435 14.0067C100.696 14.0067 100.829 14.1371 100.829 14.4005V16.2338C100.829 16.5176 100.698 16.6276 100.435 16.6276H91.969C90.0718 16.6276 89.5911 17.1952 89.5911 19.0285V20.9692C89.5911 22.8025 90.0718 23.3701 91.969 23.3701H100.435C100.696 23.3701 100.829 23.5005 100.829 23.7639V25.5972C100.829 25.858 100.698 25.991 100.435 25.991H91.6648C87.7373 25.991 86.4717 24.7483 86.4717 21.5829V18.4404C86.4717 15.2775 87.7373 14.0119 91.6648 14.0119H100.435V14.0067Z"
          fill="black"
        />
        <path
          d="M105.538 9.62164C105.865 9.62164 106.019 9.77506 106.019 10.1023V25.5052C106.019 25.8324 105.865 25.9859 105.538 25.9859H103.401C103.073 25.9859 102.92 25.8324 102.92 25.5052V10.1023C102.92 9.77506 103.073 9.62164 103.401 9.62164H105.538Z"
          fill="black"
        />
        <path
          d="M119.896 14.0067C122.885 14.0067 123.67 14.9451 123.67 17.5839V19.6575C123.67 20.5959 123.276 20.9002 122.427 20.9002H111.41V21.4243C111.41 22.8 111.911 23.365 113.81 23.365H122.908C123.169 23.365 123.302 23.4954 123.302 23.7588V25.5921C123.302 25.8529 123.171 25.9859 122.908 25.9859H113.504C109.576 25.9859 108.311 24.7432 108.311 21.5778V18.4353C108.311 15.2724 109.576 14.0067 113.504 14.0067H119.896ZM111.41 18.9595H120.246C120.507 18.9595 120.617 18.8725 120.617 18.5887V17.6734C120.617 16.7784 120.29 16.4307 119.285 16.4307H113.808C111.911 16.4307 111.407 16.9753 111.407 18.3714V18.9595H111.41Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default LogoDark;
