import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const BounceClub: React.FC<IconProps> = ({
  width = "18px",
  height = "18px",
}) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className="telegram-svg"
      viewBox="0 0 24 20"
      fill="none"
      style={{ fill: "none", width, height }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M11.2245 0.000768754C11.2225 0.122536 11.2196 0.244303 11.2119 0.366073C11.1425 1.4843 10.7258 2.5773 10.0325 3.45732C9.76669 3.80468 9.44161 4.09917 9.13599 4.41011C7.29299 6.25409 5.449 8.09664 3.60598 9.94108C3.07428 10.466 2.76962 11.2058 2.76865 11.9524C1.84592 11.9529 0.923211 11.9534 0.000976562 11.952C0.00291648 11.8462 0.00534446 11.7409 0.00971029 11.6356C0.0790855 10.3045 0.647171 9.00674 1.57862 8.05344C3.59045 6.04065 5.60375 4.0288 7.61559 2.01551C8.14925 1.49012 8.45487 0.748841 8.45632 0.000768754C9.37903 0.00028357 10.3017 -0.000686798 11.2245 0.000768754Z"
          fill="#272727"
          className="fill-color"
        />
        <path
          d="M11.0741 10.6556C11.1745 11.0801 11.223 11.5162 11.2249 11.9523C10.3022 11.9543 9.379 11.9499 8.45628 11.9543C8.4529 11.3906 8.28554 10.8273 7.96632 10.3616C7.59614 9.80948 7.02563 9.4015 6.38916 9.21522C7.08676 8.52001 7.78098 7.82191 8.47908 7.1272C9.77247 7.88985 10.7335 9.19242 11.0741 10.6556Z"
          fill="#272727"
          className="fill-color"
        />
        <path
          d="M4.83626 2.73736C4.13916 3.43303 3.44443 4.13066 2.74632 4.82583C1.60481 4.15004 0.717024 3.05802 0.294963 1.79959C0.0989682 1.22132 0.00194033 0.611025 0 0.000729748C0.923204 0.000244564 1.84593 0.000729325 2.76913 0.000244141C2.77058 0.643528 2.99374 1.28342 3.3998 1.7831C3.7656 2.23912 4.27548 2.57387 4.83626 2.73736Z"
          fill="#272727"
          className="fill-color"
        />
        <path
          d="M4.22763 0C4.22536 0.201943 4.26712 0.404154 4.35834 0.588742C4.63486 1.21165 5.40864 1.54493 6.05142 1.31402C6.61171 1.14077 6.99841 0.576466 6.99961 0H4.22763Z"
          fill="#272727"
          className="fill-color"
        />
        <path
          d="M6.99968 11.953C7.00051 11.8253 6.9827 11.6971 6.94399 11.5726C6.89498 11.3999 6.81204 11.2374 6.70095 11.0967C6.2973 10.5606 5.48712 10.4069 4.91422 10.7561C4.48658 10.9936 4.23228 11.4727 4.22754 11.953H6.99968Z"
          fill="#272727"
          className="fill-color"
        />
      </svg>
    </SvgIcon>
  );
};

export default BounceClub;
